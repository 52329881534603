// import React from 'react';
// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
// const icons = {
//   LoginOutlined,
//   ProfileOutlined
// };

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const repositoryTables = {
  id: 'repository',
  title: 'Repository Tables',
  type: 'group',
  children: [
    {
      id: 'Sales',
      title: 'Sales',
      type: 'item',
      url: '/repository/sales',
    },
    {
      id: 'Returns',
      title: 'Returns',
      type: 'item',
      url: '/repository/returns',
    }
  ]
};

export default repositoryTables;
