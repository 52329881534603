import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../../layout/MainLayout/Header";
import { Box, Button, CircularProgress } from "@mui/material";
import AnnouncementBarCMS from "./AnnouncementBarCMS";
import { fetchCmsMetaObjects } from "../shopifyCms/fetchCmsMetaObjects";
import { Typography } from "@mui/material";

const Cms = () => {
  const cookies = new Cookies();

  const [showCms, setShowCms] = useState({});
  const [cms, setCms] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState({});

  const navigate = useNavigate();

  const isloggedin = async () => {
    let accessToken = await cookies.get("jwt");
    let refreshToken = await cookies.get("refresh");
    if (accessToken === undefined && refreshToken === undefined) {
      navigate("/");
    } else if (!accessToken) {
      await fetch(
        `${process.env.REACT_APP_FS_SERVER_BASE_URL}/v0/api/auth/refreshtoken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":
              process.env.REACT_APP_FS_SERVER_BASE_URL,
          },
          body: JSON.stringify({ refreshToken: refreshToken }),
        }
      )
        .then(async (response) => {
          const json = await response.json();
          let jwtExpire = new Date();
          jwtExpire.setTime(
            jwtExpire.getTime() + json.allTokens.jwt.expireTime * 1000
          );
          let refreshExpire = new Date();
          refreshExpire.setTime(
            refreshExpire.getTime() + json.allTokens.refresh.expireTime * 1000
          );
          cookies.set("jwt", json.allTokens.jwt.token, {
            expires: jwtExpire,
          });
          cookies.set("refresh", json.allTokens.refresh.token, {
            expires: refreshExpire,
          });
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    isloggedin();

    fetchCmsMetaObjects("cms_data")
      .then((res) => {
        const data = [];
        for (let item of res) {
          try {
            if (item?.fields?.[1]?.key === "name") {
              data.push({
                [item?.fields?.[1]?.value]: {
                  id: item?.id,
                  data: JSON.parse(item?.fields?.[0]?.value),
                },
              });
            }
          } catch (err) {
            console.error("error displaying cms  metaobjects");
          }
        }
        setCmsData(data);
      })
      .catch((err) => console.error("error fetching cms metaobjects"));
    fetchCmsMetaObjects("cms")
      .then((res) => {
        const data = [];
        for (let item of res) {
          try {
            if (item?.fields?.[2]?.key === "name") {
              data.push({
                [item?.fields?.[2]?.value]: {
                  id: item?.id,
                  dataOrder: JSON.parse(item?.fields?.[1]?.value),
                  data: JSON.parse(item?.fields?.[0]?.value),
                },
              });
            }
          } catch (err) {
            console.error("error displaying cms  metaobjects");
          }
        }
        setCms(data);
      })
      .catch((err) => console.error("error fetching cms metaobjects"));
  }, []);

  const handleCmsSelection = (cmsType, name) => {
    if (cmsType === "cms") {
      cms.forEach((item) => {
        if (item?.[name]) {
          setShowCms({ ...item?.[name], name });
        }
      });
    } else if (cmsType === "cmsData") {
      cmsData.forEach((item) => {
        if (item?.[name]) {
          setShowCms({ ...item?.[name], name });
        }
      });
    }
  };

  return (
    <Box>
      <Header />
      {(isLoading || apiRes?.message) && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {apiRes?.message ? (
              <Box
                sx={{
                  p: 2,
                  backgroundColor: apiRes?.status === 200 ? "green" : "red",
                }}
              >
                <Typography sx={{ color: "#ffffff", fontSize: "20px", mb: 1 }}>
                  {apiRes?.message}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsLoading(false);
                    setApiRes({});
                  }}
                >
                  Close
                </Button>
              </Box>
            ) : (
              <CircularProgress color="inherit" />
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 9, mb: 2, mx: { xs: 2, md: 6 } }}>
        <Button
          onClick={() => handleCmsSelection("cmsData", "AnnouncementBar")}
          variant="contained"
        >
          Announcement Bar Cms
        </Button>
      </Box>
      <AnnouncementBarCMS
        show={showCms?.name === "AnnouncementBar" ? true : false}
        data={showCms?.name === "AnnouncementBar" ? showCms : {}}
        setIsLoading={setIsLoading}
        setApiRes={setApiRes}
      />
    </Box>
  );
};

export default Cms;
