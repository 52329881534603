import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import { MainRoutes, RepositoryRoutes, BqDashboardRoutes, ShopifyCmsRoutes, ShopifyCollectionRoutes, CmsRoutes, CmsJsonRoutes, BlogEditRoutes } from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, RepositoryRoutes, BqDashboardRoutes, ShopifyCmsRoutes, ShopifyCollectionRoutes, CmsRoutes, CmsJsonRoutes, BlogEditRoutes]);
}
