import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = { products: [] };
// ==============================|| SLICE - MENU ||============================== //
const shopifyProductsSlice = createSlice({
  name: 'shopifyProductsSlice',
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload.products
    },
    addProducts(state, action) {
      const newProducts = action.payload.products;
      state.products.push(...newProducts);
    }}
});

export default shopifyProductsSlice.reducer;
export const { setProducts, addProducts } = shopifyProductsSlice.actions;